import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAuth } from 'commons/auth'
import { Button, Detail, VisualizationAttr } from 'commons/components'

import deleteUser from '../services/deleteUser.js'

const DetailUser = ({ user }) => {
  const { checkPermission } = useAuth()
  const navigate = useNavigate()

  const pilihRole = async () => {
    navigate('/settings/user/change-role?' + `id=${user.id}`)
  }

  const ubah = async () => {
    navigate('/settings/user/ubah?' + `id=${user.id}`)
  }

  const hapus = async () => {
    await deleteUser({
      id: user.id,
    })
    navigate('/settings/user')
  }

  return (
    <Detail>
      {/* Data Binding User Data */}
      <VisualizationAttr label="Name" content={user?.name} />
      <VisualizationAttr label="Email" content={user?.email} />
      <VisualizationAttr
        label="Allowed Permissions"
        content={user?.allowedPermissions}
      />
      <VisualizationAttr label="Role" content={user?.userRolesName} />
      <div class="card-actions justify-end">
        {/* View Element Event User Element*/}
        {checkPermission('administrator') && (
          <Button onClick={hapus} variant="tertiary">
            Hapus
          </Button>
        )}
        {checkPermission('administrator') && (
          <Button onClick={ubah} variant="secondary">
            Ubah
          </Button>
        )}
        {checkPermission('administrator') && (
          <Button onClick={pilihRole} variant="secondary">
            Pilih Role
          </Button>
        )}
      </div>
    </Detail>
  )
}

export default DetailUser
